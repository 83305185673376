import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import styles from './ProductList.module.css';
import { BsCart2 } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { MdOutlineNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import { Loader } from '../Loader/Loader';

const PrevArrow = ({ onClick }) => (
  <button className={`${styles.arrow} ${styles.prev}`} onClick={onClick}>
    <GrFormPrevious />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button className={`${styles.arrow} ${styles.next}`} onClick={onClick}>
    <MdOutlineNavigateNext />
  </button>
);

const ProductsList = () => {
  const [sections, setSections] = useState([]);
  const [files, setFiles] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [sectionsResponse, filesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_MAIN_URL}/product/main`),
          axios.get(`${process.env.REACT_APP_MAIN_URL}/file`)
        ]);

        setSections(sectionsResponse.data);
        setFiles(filesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
        setTimeout(() => setIsLoaded(true), 100);
      }
    };

    fetchData();
    fetchCartDetails();
  }, []);

  const fetchCartDetails = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/order/basket/detail/`, {
        headers: {
          Authorization: `token ${token}`
        }
      });
      setCartItems(response.data.items);
    } catch (error) {
      console.error('Error fetching cart details', error);
    }
  };

  const getProductImage = (productId) => {
    const file = files.find(file => file.product === productId && file.main_file);
    return file ? file.file : null;
  };

  const handleAddToCart = async (productId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Пожалуйста, войдите в систему');
      return;
    }

    try {
      const existingItem = cartItems.find(item => item.product === productId);

      if (existingItem) {
        await axios.put(
          `${process.env.REACT_APP_MAIN_URL}/order/basket/items/${existingItem.id}/update/`,
          { quantity: existingItem.quantity + 1 },
          { headers: { Authorization: `token ${token}` } }
        );
        setCartItems(cartItems.map(item =>
          item.product === productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        ));
        toast.success('Количество товара обновлено в корзине');
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_MAIN_URL}/order/basket/items/create/`,
          { product: productId, quantity: 1 },
          { headers: { Authorization: `token ${token}` } }
        );
        setCartItems([...cartItems, { ...response.data }]);
        toast.success('Товар успешно добавлен в корзину');
      }
    } catch (error) {
      console.error('Error in handleAddToCart:', error);
      toast.error('Ошибка при добавлении товара в корзину');
    }
  };

  const createSliderSettings = (numProducts) => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: numProducts > 4,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          arrows: numProducts > 3,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 2,
          arrows: numProducts > 2,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          arrows: numProducts > 1,
        }
      }
    ]
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={`container ${isLoaded ? styles.loaded : ''}`}>
      <div className={styles.ls}>
        {sections.map(section => (
          <div key={section.id} className={styles.section}>
            <h2 className={styles.sectionName}>{section.name}</h2>
            <Slider {...createSliderSettings(section.products.length)} className={styles.ls}>
              {section.products.map(product => (
                <div className={`${styles.dn} ${isLoaded ? styles.fadeIn : ''}`} key={product.id}>
                  <div className={styles.productCard}>
                    {product.discount && (
                      <div className={styles.ds}>
                        <p className={styles.discountL}>
                          {product.discount_percentage}%
                        </p>
                      </div>
                    )}
                    <Link to={`/product/${product.id}`}>
                      <img
                        src={getProductImage(product.id)}
                        alt={product.name}
                        className={styles.productImage}
                      />
                      <h3 className={styles.productTitle}>{product.name}</h3>
                    </Link>
                    <div className={styles.priceBlock}>
                      <div className="">
                        {product.old_price > 0 && (
                          <p className={styles.productOldPrice}>
                            <span>{product.old_price} сом</span>
                          </p>
                        )}
                        <p className={styles.productPrice}>
                          {product.price} сом
                        </p>
                      </div>
                      <button className={styles.btn} onClick={() => handleAddToCart(product.id)}><BsCart2 /></button>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsList;
