import React, { useState } from "react";
import axios from "axios";
import styles from "./Registration.module.css";
import { Link, useNavigate } from "react-router-dom";

export const Registration = () => {
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email) {
      newErrors.email = "Email обязателен для заполнения";
    }

    if (!formData.firstName) {
      newErrors.firstName = "Имя обязательно для заполнения";
    }

    if (!formData.password) {
      newErrors.password = "Пароль обязателен для заполнения";
    } else if (formData.password.length < 6) {
      newErrors.password = "Пароль должен содержать не менее 6 символов";
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Пароли не совпадают";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/users/register/`, {
        email: formData.email,
        password: formData.password,
        first_name: formData.firstName,
      });

      if (response.status === 201) {
        navigate("/login");
      } else {
        setErrors({ general: "Регистрация не удалась" });
      }
    } catch (error) {
      setErrors({ general: "Регистрация не удалась" });
    }
  };

  return (
    <div className={styles.registration}>
      <div>
        <h2 className={styles.registration__title}>Регистрация</h2>
        <h3 className={styles.registration__text}>
          Пожалуйста, введите корректные данные!
        </h3>
        <form className={styles.registration__form} onSubmit={handleSubmit}>
          <input
            className={styles.intRegistration}
            type="text"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && <p className={styles.error}>{errors.email}</p>}

          <input
            className={styles.intRegistration}
            type="text"
            name="firstName"
            placeholder="Имя"
            value={formData.firstName}
            onChange={handleInputChange}
          />
          {errors.firstName && <p className={styles.error}>{errors.firstName}</p>}

          <input
            className={styles.intRegistration}
            type="password"
            name="password"
            placeholder="Пароль должен содержать не менее 6 символов"
            value={formData.password}
            onChange={handleInputChange}
          />
          {errors.password && <p className={styles.error}>{errors.password}</p>}

          <input
            className={styles.intRegistration}
            type="password"
            name="confirmPassword"
            placeholder="Подтверждение пароля"
            value={formData.confirmPassword}
            onChange={handleInputChange}
          />
          {errors.confirmPassword && (
            <p className={styles.error}>{errors.confirmPassword}</p>
          )}

          <button type="submit" className={styles.registrationBtn}>
            Зарегистрироваться
          </button>

          {errors.general && <p className={styles.error}>{errors.general}</p>}
        </form>
      </div>
    </div>
  );
};

export default Registration;
