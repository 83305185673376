import { Route, Router, Routes } from 'react-router';
import './App.css';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { Login } from './pages/Login/Login';
import { Registration } from './pages/Registration/Registration';
import { Search } from './pages/Search/Search';
import { Main } from './pages/Main/Main';
import { ProductDetail } from './pages/ProductDetail/ProductDetail';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Profile } from './pages/Profile/Profile';
import CategoryPage from './pages/CategoryPage/CategoryPage';
import { Basket } from './pages/Basket/Basket';
import { Order } from './pages/Order/Order';
import { MyOrder } from './pages/MyOrder/MyOrder';
import { Loader } from './components/Loader/Loader';

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/login' element={<Login />} />
        <Route path='/search' element={<Search />} />
        <Route path='/registration' element={<Registration />} />
        <Route path='/product/:id' element={<ProductDetail />} />
        <Route path='/category/:id' element={<CategoryPage />} />
        <Route path='/profile' element={<Profile />}/>
        <Route path='/basket' element={<Basket />} />
        <Route path='/order' element={<Order />} />
        <Route path='/myorder' element={<MyOrder />} />
        <Route path='/loader' element={<Loader />} />
      </Routes>
      <Footer />
      <ToastContainer />


    </div>
  );
}

export default App;
