import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import styles from "./Order.module.css";

export const Order = () => {
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [method, setMethod] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!address || !number || !method) {
      toast.error("Пожалуйста, заполните все поля и выберите метод оплаты");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Пожалуйста, войдите в систему");
      return;
    }

    const orderData = {
      address,
      number,
      method,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_MAIN_URL}/order/finalize_order/create/`,
        orderData,
        {
          headers: { Authorization: `token ${token}` },
        }
      );
      toast.success("Заказ успешно оформлен");
      setTimeout(() => {
          navigate("/");
      }, 3000); 
    } catch (error) {
      console.error("Error finalizing order:", error);
      toast.error("Ошибка при оформлении заказа");
    }
  };

  const handleMethodChange = (e) => {
    if (e.target.id === "cashCheckbox") {
      setMethod("c");
    } else if (e.target.id === "cardCheckbox") {
      setMethod("m");
    }
  };

  return (
    <div className={styles.order}>
      <div className={styles.order_header}>
        <h2 className={styles.subtitle}>Контактные данные</h2>
      </div>
      <form className={styles.orderForm} id="orderForm" onSubmit={handleSubmit}>
        <input
          type="text"
          className={styles.order__int}
          name="address"
          placeholder="Адрес"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <input
          type="text"
          className={styles.order__int}
          name="number"
          placeholder="Номер"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
        <div className={styles.checkbox}>
          <div>
            <input
              type="checkbox"
              id="cashCheckbox"
              className={styles.intcheckbox}
              checked={method === "c"}
              onChange={handleMethodChange}
            />
            <label htmlFor="cashCheckbox">Оплата наличными</label>
          </div>
          <div>
            <input
              type="checkbox"
              className={styles.intcheckbox}
              id="cardCheckbox"
              checked={method === "m"}
              onChange={handleMethodChange}
            />
            <label htmlFor="cardCheckbox">Оплата картой</label>
          </div>
        </div>
        <button type="submit" className={styles.submitBtn}>
          Подтвердить заказ
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};
