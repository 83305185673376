import React from "react";
import { FaUserLarge } from "react-icons/fa6";
import { BsFillBagCheckFill } from "react-icons/bs";
import { AiOutlineAlignRight } from "react-icons/ai";
import { BsArrowLeftShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import styles from "../../pages/Profile/Profile.module.css"; 

const MenuProfile = ({  }) => {
  return (
    <div className={styles['mobile-menu']}>
      <nav>
        <ul>
          <Link className={styles.mb}>
            <FaUserLarge className={styles.icon} />
            <a href="#" className={styles.mbNav}>
              Личные данные
            </a>
          </Link>
          <Link to={"/basket"} className={styles.mb}>
            <BsFillBagCheckFill className={styles.icon} />
            <a className={styles.mbNav}>Текущая корзина</a>
          </Link>
          <Link to={"/myorder"} className={styles.mb}>
            <AiOutlineAlignRight className={styles.icon} />
            <a className={styles.mbNav}>Мои заказы</a>
          </Link>
          <Link to="/" className={styles.mb}>
            <BsArrowLeftShort className={styles.icon} />
            <a className={styles.mbNav}>Назад</a>
          </Link>
        </ul>
      </nav>
    </div>
  );
};

export default MenuProfile;
