import React from 'react'
import styles from './Search.module.css'
import { BiSearch } from 'react-icons/bi';
export const Search = () => {

  return (
    <div className={styles.searchPage}>
        <div className={styles.search}>
          <input
            type="text"
            placeholder="Поиск..."
            className={styles.search_input}
          />
          <button className={styles.search_btn}>
            <BiSearch className={styles.searchIcon} />
          </button>
        </div>
    </div>
  )
}