import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './MyOrder.module.css';
import Sidebar from '../../components/Sidebar/Sidebar';
import { IoIosCheckmark } from "react-icons/io";
import { Loader } from '../../components/Loader/Loader';  // Импорт кастомного Loader компонента

export const MyOrder = () => {
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Токен не найден');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/order/`, {
          headers: {
            Authorization: `token ${token}`
          }
        });
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders', error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    const fetchProductDetailsAndFiles = async () => {
      setLoadingProducts(true);
      try {
        const productIds = orders.map(order => order.product);
        const productRequests = productIds.map(id => axios.get(`${process.env.REACT_APP_MAIN_URL}/product/${id}/`));
        const productResponses = await Promise.all(productRequests);

        setProducts(productResponses.map(res => res.data));

        const fileResponse = await axios.get(`${process.env.REACT_APP_MAIN_URL}/file`);
        setFiles(fileResponse.data);
      } catch (error) {
        console.error('Error fetching product details and files', error);
      } finally {
        setLoadingProducts(false);
      }
    };

    if (orders.length > 0) {
      fetchProductDetailsAndFiles();
    }
  }, [orders]);

  const getProductImage = (productId) => {
    const file = files.find(file => file.product === productId && file.main_file);
    return file ? file.file : null;
  };

  if (loading || loadingProducts) {
    return <Loader />;  // Использование кастомного Loader компонента
  }

  return (
    <div className={styles.myOrderContent}>
      <div>
        <Sidebar />
      </div>
      <div className={styles.orders}>
        <h2 className={styles.subtitle}>Мои заказы</h2>
        <div className={styles.orderItemsContent}>
          {orders.length > 0 ? (
            orders.map(order => {
              const product = products.find(p => p.id === order.product);
              if (!product) {
                return null;
              }
              return (
                <div key={order.id} className={styles.orderItem}>
                  <div className="">
                    <img
                      src={getProductImage(product.id)}
                      alt={product.name}
                      className={styles.productImage}
                    />
                    <p className={styles.productName}>{product.name}</p>
                  </div>

                  <div className={styles.orderDetails}>
                    <div className={styles.orderInfo}>
                      <p className={styles.productPrice}>{order.total_price} сом</p>
                      <p className={styles.productQuantity}> x {order.quantity}</p>
                    </div>
                    <button className={styles.orderBtn}><IoIosCheckmark /></button>
                  </div>

                </div>
              );
            })
          ) : (
            <p>Нет заказов</p>
          )}
        </div>
      </div>
    </div>
  );
};
