import React from "react";
import { FaSearch } from "react-icons/fa";
import { IoCart } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { Link } from "react-router-dom";
import styles from './MobileMenu.module.css'

export const MobileMenu = () => {
    return (
        <div className={styles['mobile-menu']}>
            <nav>
                <ul>
                    <Link to={'/'} className={styles.mb}>
                    <IoHome className={styles.icon} />
                    <a className={styles.mbNav}>Главная</a>
                    </Link>

                    <Link to={"/myorder"} className={styles.mb}>
                        <FaUser className={styles.icon} />
                        <a className={styles.mbNav}>Кабинет</a>
                    </Link>

                    <Link to="/" className={styles.mb}>
                        <FaSearch className={styles.icon} />
                        <a className={styles.mbNav}>Поиск</a>
                    </Link>

                    <Link to={"/basket"} className={styles.mb}>
                        <IoCart className={styles.icon} />
                        <a className={styles.mbNav}>Корзина</a>
                    </Link>
                </ul>
            </nav>
        </div>
    );
};

