import React from 'react';
import styles from './Footer.module.css'; // Подключаем стили
import webbuyLogo from '../../images/snapedit_1721337053095.png'
import { FaTelegramPlane, FaWhatsapp, FaInstagram } from "react-icons/fa";

export const Footer = () => {
    return (
        <div className="">
            <footer className={styles.footer}> 
                <div className="container">
                    <div className={styles.row}>
                        <div className={styles['footer-right']}>
                            <h2>Информация</h2>
                            <ul>
                                <li><a href="#">Каталог</a></li>
                                <li><a href="#">Новинки</a></li>
                                <li><a href="#">О Сайте</a></li>
                                <li><a href="#">Контакты</a></li>
                                <li><a href="#">Главное</a></li>
                            </ul>
                        </div>
                        <div className={styles['footer-center']}>
                            <img src={webbuyLogo} alt="" className={styles['webBuy-logo']} />
                        </div>
                        <div className={styles['footer-left']}>
                            <h2>Контакт</h2>
                            <p>ТЦ Мастер дом, Жукеева-Пудовкина, 146, 1 этаж</p>
                            <ul>
                                <li><a href="#">+996 (701) 511 487</a></li>
                                <li><a href="#">+996 (703) 325 787</a></li>
                                <li><a href="#">+996 (705) 420 172</a></li>
                            </ul>
                            <h6>с 10:00 до 19:00 (без выходных)</h6>
                        </div>
                    </div>
                    <div className={styles['row-phone']}>
                <div className={styles['footer-right']}>
                    <h2>Информация</h2>
                    <ul>
                        <li><a href="#">Каталог</a></li>
                        <li><a href="#">Новинки</a></li>
                        <li><a href="#">О Сайте</a></li>
                        <li><a href="#">Контакты</a></li>
                        <li><a href="#">Главное</a></li>
                    </ul>
                </div>
                <div className={styles['footer-left']}>
                    <h2>Контакт</h2>
                    <p>​ТЦ Мастер дом​Жукеева-Пудовкина, 146 ​1 этаж​</p>
                    <ul>
                        <li><a href="#">+996 (701) 511 487</a></li>
                        <li><a href="#">+996 (703) 325 787</a></li>
                        <li><a href="#">+996 (705) 420 172</a></li>
                    </ul>
                    <h6>с 10:00 до 19:00 (без выходных)</h6>
                </div>
                <div className={styles['footer-center']}>
                    <img src={webbuyLogo} alt="" className={styles['webBuy-logo']} />
                </div>
            </div>
                </div>
            </footer>
            <div className={styles['footer-bottom']}>
                    <div className={styles['info-webbuy']}>
                        <p>Web-Buy © 2023</p>
                    </div>
                    <div className={styles['icon-web']}>
                        <a href="https://instagram.com/webbuy.kg?igshid=MmIzYWVlNDQ5Yg==" target="_blank" className={styles['link-web']}><FaInstagram /></a>
                        <a href="#" className={styles['link-web']}><FaTelegramPlane /></a>
                        <a href="#" className={styles['link-web']}><FaWhatsapp /></a>
            </div>
            </div>
        </div>
    )
}
