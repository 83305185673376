import React, { useState, useEffect } from "react";
import styles from "./Login.module.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/profile');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/users/login/`, {
        email,
        password,
      });

      localStorage.setItem("token", response.data.token);
      navigate("/profile");
    } catch (error) {
      setError("Неправильный email или пароль. Попробуйте снова.");
    }
  };

  return (
    <div>
      <div className={styles.login}>
        <div className="">
          <h2 className={styles.login__title}>Авторизоваться</h2>
          <h3 className={styles.login__text}>
            Чтобы войти на сайт используйте ваш email и пароль, которые были
            указаны при регистрации на сайт
          </h3>
          <form className={styles.login__form} onSubmit={handleLogin}>
            <input
              className={styles.intLogin}
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className={styles.intLogin}
              type="password"
              placeholder="Ваш пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <p className={styles.error}>{error}</p>}
            <button type="submit" className={styles.loginBtn}>
              Войти
            </button>
          </form>
          <Link to={"/registration"} className={styles.login__nav}>
            Зарегистрироваться на сайт!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
