import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './Basket.module.css'; 
import Sidebar from "../../components/Sidebar/Sidebar";
import { IoClose } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";
import { BiWinkSmile } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';  // Добавлено для использования кастомного Loader компонента
import { MobileMenu } from '../../components/MobileMenu/MobileMenu';

export const Basket = () => {
  const [token, setToken] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [files, setFiles] = useState([]);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);  
  const showMenuDrawer = () => setOpenMenu(true);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    if (token) {
      fetchCartDetails();
      fetchProductFile();
    }
  }, [token]);

  const fetchCartDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/order/basket/detail/`, {
        headers: {
          Authorization: `token ${token}`
        }
      });
      setCartItems(response.data.items);
      fetchProducts(response.data.items);
    } catch (error) {
      console.error('Error fetching cart details', error);
    }
  };

  const fetchProducts = async (items) => {
    try {
      const promises = items.map(item =>
        axios.get(`${process.env.REACT_APP_MAIN_URL}/product/${item.product}/`)
      );
      const responses = await Promise.all(promises);
      setProducts(responses.map(response => response.data));
      setIsLoading(false);  // Отключение состояния загрузки после загрузки данных
    } catch (error) {
      console.error('Error fetching product details', error);
    }
  };

  const fetchProductFile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/file`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching the files data', error);
    }
  };

  const getProductImage = (productId) => {
    const file = files.find(file => file.product === productId && file.main_file);
    return file ? file.file : null;
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = products.find(p => p.id === item.product);
      if (product) {
        return total + product.price * item.quantity;
      }
      return total;
    }, 0);
  };

  const updateCartItemQuantity = async (itemId, newQuantity) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_MAIN_URL}/order/basket/items/${itemId}/update/`,
        { quantity: newQuantity },
        {
          headers: {
            Authorization: `token ${token}`,
          }
        }
      );
      setCartItems(prevItems =>
        prevItems.map(item =>
          item.id === itemId ? { ...item, quantity: newQuantity } : item
        )
      );
    } catch (error) {
      console.error('Error updating cart item quantity', error);
    }
  };

  const handleIncreaseQuantity = (itemId, currentQuantity) => {
    const newQuantity = currentQuantity + 1;
    updateCartItemQuantity(itemId, newQuantity);
  };

  const handleDecreaseQuantity = (itemId, currentQuantity) => {
    if (currentQuantity > 1) {
      const newQuantity = currentQuantity - 1;
      updateCartItemQuantity(itemId, newQuantity);
    }
  };

  const deleteCartItem = async (itemId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_MAIN_URL}/order/basket/items/${itemId}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`
          }
        }
      );
  
      setCartItems(prevItems => prevItems.filter(item => item.id !== itemId));
    } catch (error) {
      console.error('Error deleting cart item', error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.basketPage}>
      <div className={styles.sidebar}>
        <Sidebar showMenuDrawer={showMenuDrawer} isSuperUser={isSuperUser} />
      </div>
      <div className={styles.basket}>
        <h1 className={styles.subtitle}>Корзина</h1>
        {cartItems.length > 0 ? (
          cartItems.map(item => {
            const product = products.find(p => p.id === item.product);
            if (!product) {
              return null;
            }
            return (
              <div className={styles.cartItemsContent}>
              <div key={item.id} className={styles.cartItem}>
                <div className={styles.cartItemImage}>
                  <img
                    src={getProductImage(product.id)}
                    alt={product.name}
                    className={styles.productImage}
                  />
                </div>

                <div className={styles.cartItemContent}>
                  <p className={styles.cartItemTitle}>{product.name}</p>
                </div>

                <div className={styles.counter}>
                  <button onClick={() => handleDecreaseQuantity(item.id, item.quantity)}><FiMinus /></button>
                  <p>{item.quantity}</p>
                  <button onClick={() => handleIncreaseQuantity(item.id, item.quantity)}><FiPlus /></button>
                </div>

                <div className={styles.cartItemPrice}>
                  <p>{product.price} сом</p>
                </div>

                <div className="">
                <button className={styles.btnClose} onClick={() => deleteCartItem(item.id)}><IoClose /></button>
                </div>
              </div>
              </div>
            );
          })
        ) : (
          <div className={styles.noProducts}>
          <p>Корзина пуста</p>
          <BiWinkSmile />
        </div>        )}
        <div className={styles.basketNav}>
          <p className={styles.total}>Итого: {calculateTotal()} сом</p>
          <Link className={styles.orderBtn} to={'/order'}>перейти к заказу</Link>
        </div>
      </div>
      <MobileMenu />
    </div>
  );
};
