import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styles from './Category.module.css'; 

export const Category = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/category/`);
        setCategories(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Ошибка при получении категорий', error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // if (loading) {
  //   return ;
  // }

  return (
    <div className={styles.categoryContainer}>
    <div className={styles.category}>
      {categories.map((category) => (
        <Link to={`/category/${category.id}`} key={category.id} className={styles.categoryLink}>
          <div className={styles.categoryCard}>
            <h3 className={styles.categoryName}>{category.name}</h3>
          </div>
        </Link>
      ))}
    </div>
    </div>
  );
};

export default Category;
